import { AxiosError } from "axios";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useAuth } from "react-oidc-context";

import { queryClient } from "~/api/queryClient";
import { modelQueryKeys, useUpload } from "~/api/useModels";
import { FilesStatusCard, FileStatusRow, showToast } from "~/components";
import { FilesStatusContext } from "~/contexts/FilesStatusContext";
import { FileUploadStatusType, HttpStatusCodeType } from "~/enums";

type FileStatusRowPropsExtended = {
  fileName: string;
  key: string;
  binary?: File;
  errorMsg?: string;
  status?: string;
  description: string;
  displayName: string;
  externalIdentifier: string;
};

function FilesStatusFloatingCard() {
  const auth = useAuth();
  const filesCtx = useContext(FilesStatusContext);
  const uploadModel = useUpload();

  const uploadFileHandler = useCallback(
    async (file: FileStatusRowPropsExtended, id: string) => {
      // Update the original map (the map in the files context). Set the file's status to uploading
      filesCtx.updateFile(id, { status: FileUploadStatusType.Uploading, errorMsg: "" });
      let data;

      try {
        data = await uploadModel.mutateAsync({
          file: file?.binary as File,
          description: file.description,
          displayName: file.displayName,
          externalIdentifier: file.externalIdentifier,
        });
        if (data) {
          filesCtx.updateFile(id, {
            status: FileUploadStatusType.Uploaded,
            errorMsg: "",
          });
          queryClient.invalidateQueries({ queryKey: modelQueryKeys.all });
        }
      } catch (err) {
        if (
          (err as AxiosError).message.includes(String(HttpStatusCodeType.Forbidden || HttpStatusCodeType.Unauthorized))
        ) {
          showToast("Your session has expired. Please refresh the page or log in again.", "error");
        }

        filesCtx.updateFile(id, {
          status: FileUploadStatusType.UploadingError,
          errorMsg: "An error occurred while uploading the file.",
        });
      }
    },
    [filesCtx, uploadModel],
  );

  const renderFilesRows = useMemo(() => {
    const arrayOfFiles = Array.from(filesCtx.files);

    return arrayOfFiles?.map(([id, file]) => (
      <FileStatusRow key={id} fileName={file?.binary?.name} errorMsg={file?.errorMsg} status={file?.status} />
    ));
  }, [filesCtx.files]);
  useEffect(() => {
    // Get the newly added files
    const newlyAddedFiles = new Map(
      Array.from(filesCtx.files).filter(([, file]) => file?.status === FileUploadStatusType.New),
    );

    // Upload the new files
    newlyAddedFiles.forEach((file, id) => {
      uploadFileHandler(file, id);
    });
  }, [filesCtx.files, uploadFileHandler]);
  if (!auth.isAuthenticated) {
    return null;
  }
  return (
    <FilesStatusCard
      show={filesCtx.showStatusCard}
      totalFilesCount={filesCtx.files?.size}
      onDismiss={() => filesCtx.setShowStatusCard(false)}
    >
      {renderFilesRows}
    </FilesStatusCard>
  );
}

export default FilesStatusFloatingCard;
