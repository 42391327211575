import React, { Suspense, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Outlet, useLocation } from "react-router-dom";

import showToast from "~/components/Toast/Toast";
import {
  CustomSystemIcon,
  DiamondIcon,
  DocsIcon,
  Home,
  LoadingSpinner,
  MagicDocFile,
  Settings,
  Sliders,
} from "../../../assets/svg";
import { RoutePathType, SettingsSubpage } from "../../../enums";
import { UserMembershipContext } from "../../../contexts/UserMembershipContext";
import { ThemeColors } from "../../../themes";
import { NavItem, SideBarProps } from "../../../types/option";
import { getEnv } from "../../../utils/get_env";
import TopBanner from "../../TopBanner";
import Header from "../Header";
import SideBar from "../SideBar";

interface CollapsedSidebarLayoutProps extends Pick<SideBarProps, "activeNavItem" | "navLists"> {
  children: React.ReactNode | React.ReactNode[];
  currentPageTitle?: string;
}

export const LayoutWithSideBar = ({
  activeNavItem,
  children,
  currentPageTitle,
  navLists,
}: CollapsedSidebarLayoutProps) => (
  <div>
    <Header currentPageTitle={currentPageTitle} fullSize={false} />
    <main className="max-w-full">
      {getEnv("VITE_ITAR") === "true" && <TopBanner />}

      <div className={`${getEnv("VITE_ITAR") === "true" ? "pt-33px" : ""}`}>
        <SideBar navLists={navLists} activeNavItem={activeNavItem} fullSize={false} />

        <div className="ml-30px box-border pl-50px pr-0px">{children}</div>
      </div>
    </main>
  </div>
);

function CollapsedSidebarLayout() {
  const [activeNavItem, setActiveNavItem] = useState("");
  const location = useLocation();
  const auth = useAuth();

  const { isCustomerAdmin } = useContext(UserMembershipContext);
  const docsBaseUrl = getEnv("VITE_DOCUMENTATION_URL") || "https://docs.dev.istari.app";
  const appsUrl = getEnv("VITE_STATIC_APPS_BASE_URL");
  const defaultMagicDocsSiteURL = getEnv("VITE_MAGIC_DOCS_URL");
  /**
   * Magic Docs app new-tab opening handler
   */
  const onClickMagicDocsNavLinkHandler = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      // Prevent the default navigation behavior of the nav item component
      e.preventDefault();

      const user = auth?.user;

      //   Check if the user data has the access token
      if (!user?.access_token) {
        showToast("Couldn't retrieve user data", "error");
        return;
      }

      //   Get the magic docs URL from the environment variables

      if (!appsUrl && !defaultMagicDocsSiteURL) {
        showToast("Magic Docs URL not found", "error");
        return;
      }

      const magicDocsTab = window.open(defaultMagicDocsSiteURL, "_blank");

      if (magicDocsTab) {
        const sendMessage = () => {
          magicDocsTab.postMessage(
            user,
            defaultMagicDocsSiteURL!, // Ensure the target origin is the same as the magic docs app URL
          );
        };

        // Send the user object via postMessage once the magic docs app tab is opened
        magicDocsTab.onload = sendMessage;

        /**
         * Fallback:
         * Retry sending the message in intervals if the window isn't fully loaded yet
         */
        let retryCount = 0;
        const maxRetries = 5;
        const retryInterval = 1000;
        const retrySendMessage = setInterval(() => {
          retryCount++;
          if (retryCount > maxRetries || magicDocsTab.closed) {
            clearInterval(retrySendMessage);
          } else {
            sendMessage(); // Try sending the message again
          }
        }, retryInterval);
      }
    },
    [appsUrl, auth?.user, defaultMagicDocsSiteURL],
  );

  const navigators = useMemo(() => {
    const arrayOfLists = [
      {
        home: {
          label: "Home",
          value: RoutePathType.Upload,
          icon: <Home />,
        },
        allSystems: {
          label: "All Systems",
          value: RoutePathType.AllSystems,
          icon: <CustomSystemIcon width="24px" height="24px" />,
        },
        allFiles: {
          label: "All Files",
          value: RoutePathType.AllFiles,
          icon: <DiamondIcon width="24px" height="24px" />,
        },
      },
      {
        documentation: {
          label: "Documentation",
          value: RoutePathType.Documentation,
          icon: <DocsIcon />,
          children: [
            {
              label: "Welcome to Istari",
              value: `${docsBaseUrl}/docs/get-started/Welcome-to-Istari`,
            },
            {
              label: "Core Features",
              value: `${docsBaseUrl}/docs/get-started/Core-Features`,
            },
            {
              label: "Magic Doc Tutorial",
              value: `${docsBaseUrl}/docs/get-started/Magic-Doc-Tutorial`,
            },
          ],
        },
        settings: {
          label: "Settings",
          value: RoutePathType.Settings,
          icon: <Settings />,
          children: [
            {
              label: "Profile Settings",
              value: SettingsSubpage.PROFILE_SETTINGS,
            },
            {
              label: "Change Password",
              value: SettingsSubpage.CHANGE_PASSWORD,
            },
            {
              label: "Developer Settings",
              value: SettingsSubpage.DEVELOPER_SETTINGS,
            },
          ],
        },
      },
      {
        magicDocs: {
          label: "Magic Docs",
          value: RoutePathType.MagicDocs,
          icon: <MagicDocFile />,
          onClick: onClickMagicDocsNavLinkHandler,
        },
      },
    ];

    const defaultSecondNavList = arrayOfLists[1];
    const { documentation, settings } = defaultSecondNavList;

    const adminPanel = {
      label: "Admin Panel",
      value: RoutePathType.AdminPanel,
      icon: <Sliders />,
    };
    const lowerSetOfNavs = isCustomerAdmin
      ? {
          documentation,
          adminPanel,
          settings,
        }
      : {
          documentation,
          settings,
        };

    const externalAppsList = arrayOfLists[2];

    const arrayOfNavs = [arrayOfLists[0], lowerSetOfNavs];

    if (appsUrl || defaultMagicDocsSiteURL) {
      arrayOfNavs.push(externalAppsList);
    }

    const navItems = Object.values(arrayOfNavs.reduce((obj, item) => Object.assign(obj, { ...item }), {})) as NavItem[];

    const navItemsValues = Object.values(
      // Convert the navigators array of objects to a single object
      arrayOfNavs.reduce((obj, item) => Object.assign(obj, { ...item }), {}),
    )?.map((item) => (item as NavItem).value);

    // convert the array of lists objects to array of arrays of objects values
    const arrayOfNavLists = arrayOfNavs.map((list) => Object.values(list)) as NavItem[][];

    return {
      navItems,
      navItemsValues,
      arrayOfNavLists,
    };
  }, [isCustomerAdmin, onClickMagicDocsNavLinkHandler, appsUrl, defaultMagicDocsSiteURL]);

  useEffect(() => {
    const currPathname = location.pathname.replace("/", "");

    if (navigators.navItemsValues?.includes(currPathname)) {
      setActiveNavItem(currPathname);
    }
  }, [location, navigators]);

  return (
    <LayoutWithSideBar
      navLists={navigators.arrayOfNavLists}
      activeNavItem={activeNavItem}
      currentPageTitle={navigators.navItems?.find((item) => item.value === activeNavItem)?.label}
    >
      <Suspense fallback={<LoadingSpinner color={ThemeColors.primary[400]} width="40px" height="40px" />}>
        <Outlet />
      </Suspense>
    </LayoutWithSideBar>
  );
}

export default CollapsedSidebarLayout;
