import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IstariLogoColor, LoadingSpinner } from "~/assets/svg";
import { ThemeColors } from "~/themes";

const RedirectPage = ({ redirectUrl = "" }: { redirectUrl?: string }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(redirectUrl);
    window.location.reload();
  }, [navigate, redirectUrl]);

  const getRedirectPageContent = () => {
    if (redirectUrl) {
      return (
        <div className="w-full">
          <LoadingSpinner color={ThemeColors.primary[400]} data-testid="loading-spinner" width="50px" height="50px" />
          <h6 className="mt-10px my-0 text-gray-700">Redirecting</h6>
        </div>
      );
    }
    return <a href="/">Go back to home page</a>;
  };

  return (
    <div>
      <div className="mb-30px">
        <IstariLogoColor data-testid="redirect-page-logo" />
      </div>
      {getRedirectPageContent()}
    </div>
  );
};

export default RedirectPage;
