import { showToast } from "../components";

export const copyToClipboard = (stringToCopy: string, infoMessage: string) =>
  navigator.clipboard
    .writeText(stringToCopy)
    .then(() => showToast(infoMessage, "success"))
    .catch(() => {
      showToast("Failed to copy to clipboard", "error");
    });

export const generateUniqueId = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};
