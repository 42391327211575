import React, { createContext, useCallback, useMemo, useState } from "react";

import { FileUploadStatusType } from "../enums";
import { generateUniqueId } from "../utils/helper";

export const initialState = {
  files: new Map(),
  showStatusCard: false,
  addFiles: (_newFiles: File[], _description: string, _displayName: string, _externalIdentifier: string) => {},
  updateFile: (
    _fileId: string,
    _newData: { data?: { progress: string }; status: string; errorMsg: string | undefined },
  ) => {},
  setShowStatusCard: (_show: boolean) => {},
};

export const FilesStatusContext = createContext(initialState);

function FilesStatusProvider({ children }: { children: React.ReactNode }) {
  const [files, setFiles] = useState(new Map());
  const [showStatusCard, setShowStatusCard] = useState(false);

  const addFiles = useCallback(
    (newFiles: File[], description: string, displayName: string, externalIdentifier: string) => {
      // create a new list (2D array) of files with unique id as the first element (map keys)
      const newFilesList = newFiles?.map((file) => [
        generateUniqueId(),
        { binary: file, description, displayName, externalIdentifier, status: FileUploadStatusType.New },
      ]);

      // add the new files to the files map local state
      setFiles((prevFiles) => new Map([...prevFiles, ...newFilesList] as any));

      setShowStatusCard(true);
    },
    [],
  );

  const updateFile = useCallback((fileId: string, newData: { status: string; errorMsg: string | undefined }) => {
    setFiles((prevFiles) => {
      // create a clone of the map state
      const newFiles = new Map(prevFiles);
      // update the file data
      newFiles.set(fileId, { ...newFiles.get(fileId), ...newData });
      // return the new map state
      return newFiles;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      files,
      addFiles,
      updateFile,
      showStatusCard,
      setShowStatusCard,
    }),
    [addFiles, files, showStatusCard, updateFile],
  );

  return <FilesStatusContext.Provider value={contextValue}>{children}</FilesStatusContext.Provider>;
}

export default FilesStatusProvider;
